"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("b-tab-item", [_c("Dashboard", {
    attrs: {
      "created-hive-consolidated": _vm.createdHiveConsolidated,
      "created-provider-consolidated": _vm.createdProviderConsolidated,
      "received-hive-consolidated": _vm.receivedHiveConsolidated,
      "send-client-consolidated": _vm.sendClientConsolidated,
      "list-last-results": _vm.computedListLastResults,
      "list-services-on-error-transactions": _vm.listServicesOnErrorTransactions,
      "is-loading-receptionist": _vm.isLoadingReceptionist
    }
  })], 1), _vm._v(" "), _c("b-tab-item", [_c("details-dashboard-processing", {
    attrs: {
      "create-hive": _vm.createHive,
      "hive-provider": _vm.hiveProvider,
      "provider-hive": _vm.providerHive,
      "hive-client": _vm.hiveClient
    }
  })], 1), _vm._v(" "), _c("b-tab-item", [_c("details-dasboard-error", {
    attrs: {
      "create-hive-error": _vm.createHiveError,
      "hive-provider-error": _vm.hiveProviderError,
      "provider-hive-error": _vm.providerHiveError,
      "hive-client-error": _vm.hiveClientError
    }
  })], 1), _vm._v(" "), _c("b-tab-item", [_c("SeeMore", {
    attrs: {
      "chart-data": _vm.chartData,
      "load-chart": _vm.loadChart,
      "fill-chart-data": _vm.fillChartData
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;